import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { AuthStore } from '@api/common/store/auth'
import { ProfileDto } from '@api/merchant/dto'

import { HttpService } from './http.service'
import { FileService } from './file.service'

@Injectable()
export class AuthService {
  constructor(
    private httpService: HttpService,
    private fileService: FileService,
    private authStore: AuthStore) {
  }

  async isAdmin() {
    const isAdmin = await this.httpService.get<boolean>('/admin/v1/is-admin')

    this.authStore.update({ isAdmin })
  }

  async isMerchant() {
    return await this.httpService.get<boolean>('/merchant/v1/is-merchant')
  }
    
  async current() {
    const user = await this.httpService.get<ProfileDto>('/v1/profile')
    const authUser = _.clone(user)

    this.authStore.update({user: authUser })

    return user
  }

  async logIn(email: string, password: string, remember?: boolean) {
    const { sessionToken, user } = await this.httpService.post('/v1/auth/login', { email, password, remember })

    await this.httpService.setSessionToken(sessionToken, remember)

    return user
  }

  async logOut() {
    await this.httpService.delete('/v1/auth/logout')

    return await this.httpService.removeSessionToken()
  }

  async updatePassword(currentPassword: string, newPassword: string) {
    return await this.httpService.patch<boolean>('/v1/auth/update-password', {
      currentPassword,
      newPassword
    })
  }

  async uploadPhoto(photo: File) {
    return await this.fileService.uploadPath('/v1/auth/photo', photo, false)
  }
}
