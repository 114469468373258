export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'sessionToken'
export const HTTP_HEADER_SESSION_TOKEN_KEY = 'X-Session-Token'

export const STYLE_FILL_COLOR = 'fillColor'

export const NO_THUMNAIL_URL = './assets/images/no-image.png'
export const SHOP_NAME_PATTERN = /^(?=.{2,50}$)([\u1780-\u17FFa-zA-Z0-9]+ )*[\u1780-\u17FFa-zA-Z0-9]+$/
export const OWNER_NAME_PATTERN = /^(?=.{2,20}$)([\u1780-\u17FFa-zA-Z0-9]+[_\- ])*[\u1780-\u17FFa-zA-Z0-9]+$/
export const PASSWORD_PATTERN = /(?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Za-z]).*$/
export const VALID_NUMBER_PATTERN = /^[1-9][0-9]*$/

export const KH_CURRENCY_CONFIG = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: " រៀល",
  thousands: ",",
  nullable: true
};