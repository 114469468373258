import { Injectable } from '@angular/core'

import * as _ from 'lodash'

import { ProfileDto, UpdateProfileDto } from '@api/merchant/dto'
import { HttpService } from './http.service'

@Injectable()
export class ProfileService {
  constructor(
    private httpService: HttpService) {
    
  }

  async summary() {
    return await this.httpService.get<ProfileDto>('/v1/profile')
  }

  async update({ fullName, dateOfBirth, gender }: UpdateProfileDto) {
    return await this.httpService.patch<boolean>('/v1/profile', {
      fullName,
      gender,
      dateOfBirth
    })
  }

  async uploadPhoto(file: File) {
    const formData = new FormData()
      
    formData.append('file', file)
    formData.append('isTemporary', 'false')

    return await this.httpService.patch<boolean>('/v1/profile/photo', formData)
  }
}
