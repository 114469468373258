import { CommonModule, CurrencyPipe } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/common/utils'

import { AuthStore, AuthQuery } from './store/auth'

import { 
  HttpService, 
  AuthService, 
  LocalStorageService, 
  FileService,  
  ProfileService, 
  WindowRef } from '@api/common/services'

import { ApiModuleOptions } from './api-common.module-options'
import { DragulaService } from 'ng2-dragula'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    FileService,
    AuthService,
    AuthStore,
    AuthQuery,
    DragulaService,
    ProfileService,
    WindowRef,
    CurrencyPipe,
    WindowRef
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiCommonModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiCommonModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
